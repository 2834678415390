<template>
  <div>
    <CModal v-model="day.id" title="Are you sure to delete selected day?" color="danger" :show.sync="showDeleteDayModal"
      @update:show="closeDeleteModal">
      <CRow>
        <CCol col="6">
          <CInput label="Name" v-model="experienceDay.name" disabled />
        </CCol>
        <CCol col="6">
          <CInput label="Order" v-model="experienceDay.order" disabled />
        </CCol>
        <CCol col="12">
          <CInput label="Description" v-model="experienceDay.description" disabled />
        </CCol>
      </CRow>
    </CModal>

    <CModal title="Are you sure to delete selected row?" color="danger" :show.sync="showDeleteRowModal"
      @update:show="closeDeleteRowModal"></CModal>

    <CModal v-model="experienceSchedule.id" :title="dayModalMode === 'edit' ? 'Edit day' : 'Add new day'" color="primary"
      :show.sync="showDayModal" @update:show="closeDayModal">
      <CRow>
        <CCol col="6">
          <CInput label="Name" v-model="experienceDay.name" />
        </CCol>
        <CCol col="6">
          <CInput label="Order" v-model="experienceDay.order" />
        </CCol>
        <CCol col="12">
          <CInput label="Description" v-model="experienceDay.description" />
        </CCol>
      </CRow>
    </CModal>

    <CModal title="Add Experience Row" :show="!!editDayId"
      @update:show="(_, event, accept) => modalAction(_, event, accept)">
      <CRow>
        <CCol col="12">
          <CSelect label="Experience type" :options="expRowOptions" @update:value="e => chooseExpType(e)" />
        </CCol>
      </CRow>
      <CRow>
        <CCol col="12">
          <CSelect v-if="this.chosenNewExpType == this.externalIntegrationString" label="External Integration"
            :options="integrations" @update:value="e => chooseIntegration(e)" />
        </CCol>
      </CRow>
    </CModal>
    <CAlert :color="alertType" v-if="message" fade>
      {{ message }}
    </CAlert>
    <h3>
      <CIcon name="cil-mood-very-good"></CIcon>
      Experience Schedule
    </h3>
    <CForm @submit.prevent="create" method="POST">
      <b-tabs content-class="mt-3">
        <b-tab title="General" active>
          <template>
            <CRow>
              <CCol style="margin-left:30px">
                <h4>
                  <CIcon name="cil-list" style="margin-bottom: 5px" class="text-primary"></CIcon>
                  General
                </h4>
              </CCol>
              <CCol class="text-right" style="margin-right:15px;">
                <div><label>Active</label></div>
                <CSwitch color="success" size="lg" :checked.sync="experienceSchedule.active"></CSwitch>
              </CCol>
            </CRow>

            <CRow style="margin-left:30px">
              <CCol col="6" style="min-width: 10rem;">
                <label>From</label>
                <ejs-datepicker v-model="experienceSchedule.startTime" :format="$dateFormatInput.split(' ')[0]"></ejs-datepicker>
              </CCol>
              <CCol col="6" style="min-width: 7rem; margin-right:5px">
                <label>To</label>
                <ejs-datepicker v-model="experienceSchedule.endTime" :format="$dateFormatInput.split(' ')[0]"></ejs-datepicker>
              </CCol>
            </CRow>
            <CRow style="margin-left:30px">
              <CCol style="min-width: 10rem;">
                <label style="width: 100%">Name
                  <a @click="toggleNameTranslations = !toggleNameTranslations">
                    <CIcon name="cil-globe-alt" style="margin-bottom: 5px" class="text-primary"></CIcon>
                  </a>
                </label>

                <div class="form-group">
                  <div class="input-group" v-if="experienceSchedule.nameTranslations[0]" v-show="!toggleNameTranslations">
                    <div class="input-group-prepend">
                      <span v-text="experienceSchedule.nameTranslations[0].languageCode" class="input-group-text"
                        v-show="!toggleNameTranslations" />
                    </div>
                    <input id="name" name="name" type="text" class="form-control"
                      v-model="experienceSchedule.nameTranslations[0].name" />
                  </div>
                </div>
                <div class="form-group" v-show="toggleNameTranslations"
                  v-for="(language, k) in experienceSchedule.nameTranslations" :key="k">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span v-text="language.languageCode" class="input-group-text" />
                    </div>
                    <input type="text" class="form-control" @id="language.languageCode"
                      v-model="experienceSchedule.nameTranslations[k].name" />
                  </div>
                </div>
              </CCol>
              <CCol>
                <CInput label="Priority" type="text" v-model="experienceSchedule.priority"></CInput>
              </CCol>
            </CRow>

            <CRow style="margin-left:30px">
              <CCol style="min-width: 10rem;">
                <label style="width: 95%">Starting from price
                  <a @click="toggleStartingPriceTranslations = !toggleStartingPriceTranslations">
                    <CIcon name="cil-globe-alt" style="margin-bottom: 5px" class="text-primary"></CIcon>
                  </a>
                </label>

                <div class="form-group">
                  <div class="input-group" v-if="experienceSchedule.startingPriceTranslations[0]"
                    v-show="!toggleStartingPriceTranslations">
                    <div class="input-group-prepend">
                      <span v-text="experienceSchedule.startingPriceTranslations[0].languageCode" class="input-group-text"
                        v-show="!toggleStartingPriceTranslations" />
                    </div>
                    <input id="name" name="name" type="text" class="form-control"
                      v-model="experienceSchedule.startingPriceTranslations[0].name" />
                  </div>
                </div>
                <div class="form-group" v-show="toggleStartingPriceTranslations"
                  v-for="(language, k) in experienceSchedule.startingPriceTranslations" :key="k">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span v-text="language.languageCode" class="input-group-text" />
                    </div>
                    <input type="text" class="form-control" @id="language.languageCode"
                      v-model="experienceSchedule.startingPriceTranslations[k].name" />
                  </div>
                </div>
              </CCol>
            </CRow>


            <CRow style="margin-left:30px">
              <CCol col="12">
                Type
              </CCol>
            </CRow>
            <CRow style="margin-left:30px">
              <CInputRadio label="Date range (days)" type="radio" name="types"
                :checked="experienceSchedule.timeSelection === 3" @change="experienceSchedule.timeSelection = 3"
                style="margin-left: 1rem;" />
              <CInputRadio label="Date range (nights)" type="radio" name="types"
                :checked="experienceSchedule.timeSelection === 4" @change="experienceSchedule.timeSelection = 4"
                style="margin-left: 1rem;" />

              <CInputRadio label="Departure / Single day" type="radio" name="types"
                :checked="experienceSchedule.timeSelection === 2" @change="experienceSchedule.timeSelection = 2"
                style="margin-left: 1rem;" />
              <CInputRadio label="Timeslot" type="radio" name="types" :checked="experienceSchedule.timeSelection === 1"
                @change="experienceSchedule.timeSelection = 1" style="margin-left: 1rem;" />
            </CRow>
            <CRow>
              <CCol>
                <div style="padding-top: 10px; margin-left: 2rem;">
                  <h4>
                    <CIcon name="cil-calendar" style="margin-bottom: 5px" class="text-primary"></CIcon>
                    Availability Start Day
                  </h4>
                  <div style="display: flex; flex-wrap: wrap;">
                    <div>
                      <CRow>
                        <div class="form-check" style="align-items: flex-start; padding-left: 0; margin-right: 1rem;"
                          v-for="(time, k) in filteredAvailability" :key="k">
                          <CCol>
                            <div style="display: block;">
                              <input :id="time.day" :value="time.weekDayName" name="weekDay" type="checkbox"
                                style="accent-color:#321fdb" v-model="time.isAvailable" />
                              <label style="padding-left: 10px; margin-bottom: 0;">{{ time.weekDayName }}</label>
                              <div v-if="experienceSchedule.timeSelection === 1"
                                v-for="group in grouped[time.weekDayName]" :key="group.day">
                                <CBadge
                                  style="background-color: white; border: 1px solid grey; border-radius: 45px; padding:5px 10px; "
                                  color="secondary">
                                  {{ group.start.hours != null && group.start.minutes != null
                                    ? group.start.hours.toString().padStart(2, '0') + ':' +
                                    group.start.minutes.toString().padStart(2, '0')
                                    : '' }}
                                  - {{ group.end.hours != null && group.end.minutes != null
                                    ? group.end.hours.toString().padStart(2, '0') + ':' +
                                    group.end.minutes.toString().padStart(2,
                                      '0')
                                    : '' }}
                                  <CButton style="background-color: transparent; box-shadow: none; padding: 0 5px;"
                                    color="secondary" class="btn-lg" @click="openEditTimeslot(group)">
                                    <CIcon name="cilPencil" />
                                  </CButton>
                                  <!-- Render your items inline here -->
                                  <div class="item-content">
                                    {{ group.details }}
                                  </div>
                                </CBadge>
                              </div>
                            </div>
                          </CCol>

                        </div>
                      </CRow>

                    </div>
                  </div>
                </div>
              </CCol>
            </CRow>
          </template>
        </b-tab>
        <b-tab title="Duration and Limits">
          <CRow style="margin-left: 1rem;">
            <CCol style="border-left: 1px solid lightgrey; margin-left: 1rem; padding-left: 2rem;">
              <h4>
                <CIcon name="cil-calendar"></CIcon> Duration and Day Limits
              </h4>
              <CRow style="align-items: flex-end;">
                <CCol v-if="experienceSchedule.duration" col="3"
                  style="width: 15rem; min-width: 15rem; max-width: 15rem;">
                  <CInput label="Days" v-model="experienceSchedule.duration.days" type="text"
                    style="width: 3rem; display: inline-block;" />
                  <CInput label="Hours" v-model="experienceSchedule.duration.hours" type="text"
                    style="width: 3rem; display: inline-block; margin-left: 1rem;" />
                  <CInput label="Minutes" v-model="experienceSchedule.duration.minutes" type="text"
                    style="width: 3rem; display: inline-block; margin-left: 1rem;" />
                </CCol>
                <CCol v-if="experienceSchedule.timeSelection === 3 || experienceSchedule.timeSelection === 4">
                  <CInput label="Min. length (days)" v-model="experienceSchedule.limit.minDays"
                    style="width: 6rem; display: inline-block; margin-left: 1rem;" />
                  <CInput label="Max. length (days)" v-model="experienceSchedule.limit.maxDays"
                    style="width: 6rem; display: inline-block; margin-left: 1rem;" />
                </CCol>
              </CRow>
              <CRow style="align-items: flex-start;">
                <CCol v-if="experienceSchedule.bookingCutoff" style="width: 15rem; min-width: 15rem; max-width: 15rem;">
                  <h4>
                    <CIcon name="cil-clock" /> Cutoff
                  </h4>
                  <CInput type="text" label="Days" v-model="experienceSchedule.bookingCutoff.days"
                    style="width: 3rem; display: inline-block;" />
                  <CInput type="text" label="Hours" v-model="experienceSchedule.bookingCutoff.hours"
                    style="width: 3rem; display: inline-block; margin-left: 1rem;" />
                  <CInput type="text" label="Minutes" v-model="experienceSchedule.bookingCutoff.minutes"
                    style="width: 3rem; display: inline-block; margin-left: 1rem;" />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </b-tab>
        <b-tab title="Capacity">
          <CRow style="margin-left:15px">
            <CCol>
              <h4>
                <CIcon name="cil-user" class="text-primary"></CIcon> Capacity
              </h4>
            </CCol>
          </CRow>
          <CRow style="margin-left:15px">
            <CCol>
              <span>Per booking</span>
              <CRow>
                <CCol col="2"></CCol>
                <CCol col="2">Disabled</CCol>
                <CCol col="2">Min</CCol>
                <CCol col="2">Max</CCol>
              </CRow>
              <CRow v-for="expRowCapac in experienceSchedule.perBookingCapacity.guestTypeSpecificCapac">
                <CCol col="2">
                  <span>{{ expRowCapac?.label }}</span>
                </CCol>
                <CCol col="2">
                  <input v-model="expRowCapac.disabled" type="checkbox" />
                </CCol>
                <CCol col="2">
                  <CInput v-model="expRowCapac.min" />
                </CCol>
                <CCol col="2">
                  <CInput v-model="expRowCapac.max" />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="2">
                  <span>Total</span>
                </CCol>
                <CCol col="2">

                </CCol>
                <CCol col="2">
                  <CInput v-model="experienceSchedule.perBookingCapacity.minTotal" />
                </CCol>
                <CCol col="2">
                  <CInput v-model="experienceSchedule.perBookingCapacity.maxTotal" />
                </CCol>
              </CRow>
            </CCol>
            <CCol>
              <span>Per departure</span>
              <CRow>
                <CCol col="2">Min</CCol>
                <CCol col="2">Max</CCol>
                <CCol col="2">Bookings</CCol>
              </CRow>
              <CRow v-for="perItemCapac in experienceSchedule.perDepartureCapacity.guestTypeSpecificCapac">
                <CCol col="2">
                  <CInput v-model="perItemCapac.min" />
                </CCol>
                <CCol col="2">
                  <CInput v-model="perItemCapac.max" />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="2">
                  <CInput v-model="experienceSchedule.perDepartureCapacity.minTotal" />
                </CCol>
                <CCol col="2">
                  <CInput v-model="experienceSchedule.perDepartureCapacity.maxTotal" />
                </CCol>
                <CCol col="2">
                  <CInput v-model="experienceSchedule.perDepartureCapacity.bookings" />
                </CCol>
              </CRow>

            </CCol>
          </CRow>
        </b-tab>
      </b-tabs>
      <CCard>
        <CCardBody>


          <CRow style="padding-top: 40px">
            <CCol col="8"></CCol>
            <CCol class="d-flex justify-content-end">
              <CButton color="secondary" class="btn-lg" @click="goBack">
                <CIcon name="cil-mood-very-good" /> Back to experience
              </CButton>
              <CButton type="submit" color="primary" style="margin-left: 15px" class="btn-lg">
                <CIcon name="cil-save" /> Save
              </CButton>
              <div v-if="experienceSchedule.timeSelection === 1">
                <CButton @click="openAddTimeslot()" class="btn-lg" color="secondary" style="margin-left: 15px">
                  <CIcon name="cil-plus" /> Add slot
                </CButton>
              </div>

            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CForm>
    <CCard v-if="experienceSchedule.id != emptyGuid">
      <CCardBody>
        <CRow>
          <CCol col="9">
            <h5>Daily Breakdown</h5>
          </CCol>
          <CCol col="3" class="text-right">
            <CButton color="secondary" class="btn text-primary" @click="showDayModal = true; dayModalMode = 'add'">
              <CIcon name="cil-plus" /> Add day
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
        <CRow v-for="day of items" :key="day.id" style="flex-direction: column; margin-left: 1rem; margin-right: 1rem;">
          <CRow
            style="flex-direction: row; align-items: center; justify-content: space-between; background-color: #768192; color: white; padding: 5px 10px;">
            <div>
              <span style="font-weight: bold; display: inline-block; min-width: 15rem;">{{ day.title }}</span>
              <CButton color="primary" @click="showDayModal = true; experienceDay = day; dayModalMode = 'edit';"
                style="margin-left: 1rem;">
                <CIcon name="cil-pencil" /> Edit
              </CButton>
              <CButton color="danger" @click="showDeleteDayModal = true; experienceDay = day;" style="margin-left: 1rem;">
                <CIcon name="cil-trash" /> Delete
              </CButton>
              <CButton color="secondary" @click="openExpRowAddModal(day.id)" class="btn text-primary"
                style="margin-left: 1rem;">
                <CIcon name="cil-plus" /> Add experience row
              </CButton>
            </div>
            <a @click="toggle(day.id)">
              <CIcon name="cil-chevron-bottom" style="cursor: pointer;"
                :style="itemsVisibility[day.id] ? 'transform: rotate(180deg);' : ''" />
            </a>
          </CRow>
          <CRow>
            <CCollapse :show="itemsVisibility[day.id]" :key="day.id">
              <CDataTable :items="day.experienceRows" :fields="rowfields" v-if="day.experienceRows.length > 0"
                style="width: -webkit-fill-available;">
                <template #Source="{ item }">
                  <td style="width: 7em;">
                    <span v-if="item.sourceType == 0">Inventory</span>
                    <span v-else-if="item.sourceType == 1">Partner Experience</span>
                    <span v-else-if="item.sourceType == 2">External Integration</span>
                  </td>
                </template>
                <template #Resource="{ item }">
                  <td>
                    <span v-if="item.inventory != null">{{ item.inventory.name }}</span>
                  </td>
                </template>
                <template #Visibility="{ item }">
                  <td>
                    <CBadge :color="getVisibilityBadge(item.type)">
                      <span v-if="item.type == 0">Row</span>
                      <span v-else-if="item.type == 1">Add-on</span>
                      <span v-else-if="item.type == 3">Option</span>
                      <span v-else>Hidden</span>
                    </CBadge>
                  </td>
                </template>
                <template #status="{ item }">
                  <td>
                    <CBadge :color="getStatusBadge(item.active)">
                      {{ item.active == true ? "Active" : "Inactive" }}
                    </CBadge>
                  </td>
                </template>
                <template #sortOrder="{ item }">
                  <td>
                    {{ item.sortOrder }}
                  </td>
                </template>
                <template #Edit="{ item }">
                  <td>
                    <CButton color="primary" @click="editExperienceRow(day.id, item.id)">
                      <CIcon name="cil-pencil" /> Edit
                    </CButton>
                  </td>
                </template>
                <template #Delete="{ item }">
                  <td>
                    <CButton color="danger" @click="
                      deleteDayId = day.id;
                    deleteRowId = item.id;
                    showDeleteRowModal = true;
                    ">
                      <CIcon name="cil-trash" /> Delete
                    </CButton>
                  </td>
                </template>
              </CDataTable>
            </CCollapse>
          </CRow>
        </CRow>
      </CCardBody>
    </CCard>
    <CModal title="Add timeslot " color="success" :show.sync="showAddTimeslot" @update:show="closeModalAdd">
      <CRow>
        <CCol>
          <label>Weekdays</label>
          <CMultiSelect :key="selectedSlotId2" ref="multiSelect" :multiple="true" :v-model="selectedWeekDaysAdd"
            :selection="true" @update="appendValueAdd($event)" optionsEmptyPlaceholder="No options placeholder"
            selectionType="text" :options="options" :selected.sync="selectedWeekDaysAdd" />
        </CCol>
      </CRow>
      <CRow>
        <CCol col="6">
          <label>Start time</label>
          <input ref="startTimeslotInput" :v-model="startOfTimeslot" @keyup="saveStartTimeslotTime(k, $event)"
            @keypress="" @keydown="saveStartTimeslotTime(k, $event)" class="form-control" type="time"
            @change="saveStartTimeslotTime(k, $event)" />
        </CCol>
        <CCol col="6">
          <label>End time</label>
          <input ref="endTimeslotInput" :v-model="endOfTimeslot" @keyup="saveEndTimeslotTime(k, $event)" @keypress=""
            @keydown="saveEndTimeslotTime(k, $event)" class="form-control" type="time"
            @change="saveEndTimeslotTime(k, $event)" />
        </CCol>
      </CRow>
      <CRow style="margin-top:1rem; margin-bottom: 1rem; margin-left:0.6rem">
        <h3>
          <CIcon name="cilFactory"></CIcon>
          Additional options
        </h3>
      </CRow>

      <CRow>
        <CCol col="6">
          <CInput label="Slot length (minutes)" v-model="addSlotLength" />

        </CCol>
        <CCol col="6">
          <CInput label="Repeat every (minutes)" v-model="addRepetLength" />

        </CCol>
      </CRow>
    </CModal>
    <CModal title="Edit timeslot" color="success" :show.sync="showEditTimeslot" @update:show="closeModalEdit">
      <CRow>
        <CCol>
          <label>Weekdays</label>
          <CMultiSelect :key="selectedSlotId" :v-model="selectedWeekDaysAdd" v-if="enteredinedit" :multiple="true"
            :selection="true" @update="appendValueAdd($event)" optionsEmptyPlaceholder="No options placeholder"
            selectionType="text" :options="options" :selected.sync="selectedWeekDaysAdd" />
        </CCol>
      </CRow>
      <CRow>
        <CCol col="6">
          <label>Start time</label>
          <input v-if="enteredinedit" @keyup="saveStartTimeslotTime(k, $event)" @keypress=""
            @keydown="saveStartTimeslotTime(k, $event)" class="form-control" v-model="formattedStartTime" type="time"
            @change="saveStartTimeslotTime(k, $event)" />
        </CCol>
        <CCol col="6">
          <label>End time</label>
          <input v-if="enteredinedit" @keyup="saveEndTimeslotTime(k, $event)" v-model="formattedEndTime" @keypress=""
            @keydown="saveEndTimeslotTime(k, $event)" class="form-control" type="time"
            @change="saveEndTimeslotTime(k, $event)" />
        </CCol>
      </CRow>
      <CRow style="margin-top:1rem; margin-bottom: 1rem; margin-left:0.6rem">
        <h3>
          <CIcon name="cilFactory"></CIcon>
          Additional options
        </h3>
      </CRow>

      <CRow>
        <CCol col="6">
          <CInput label="Slot length (minutes)" v-model="addSlotLength" />

        </CCol>
        <CCol col="6">
          <CInput label="Repeat every (minutes)" v-model="addRepetLength" />

        </CCol>
      </CRow>
      <template #footer>
        <CButton color="secondary" @click="
          closeModalEdit(null, null, false)
          ">Cancel
        </CButton>
        <CButton color="danger" @click="
          openDeleteTimeSlotConfirm()
          ">
          <CIcon name="cil-trash" /> Delete
        </CButton>

        <CButton color="success" @click="
          closeModalEdit(null, null, true)
          ">OK
        </CButton>
      </template>
    </CModal>

    <CModal title="Delete timeslot" color="danger" :show.sync="showDeleteTimeSlot" @update:show="closeModalDelete">
      <span> Are you sure that you want to delete the selected timeslots?</span>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
import { ExperienceTypesNames, SourceTypes } from '/src/common.js'
import { DatePickerComponent } from '@syncfusion/ej2-vue-calendars';
export default {
  name: "EditexperienceSchedule",
  components: {
    'ejs-datepicker': DatePickerComponent,
  },
  data: () => {
    return {
      selectedWeekDaysAdd: [],
      selectedWeekDaysEdit: [],
      selectedWeekDaysEdit: [],
      selectedSlotId: "",
      selectedSlotId2: "",
      grouped: {},
      groupedByDay: {},
      toggleNameTranslations: false,
      toggleStartingPriceTranslations: false,
      token: localStorage.getItem("api_token"),
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      experienceId: "00000000-0000-0000-0000-000000000000",
      items: [],
      options: [
        {
          value: 1,
          text: 'Monday',
        },
        {
          value: 2,
          text: 'Tuesday',
        },
        {
          value: 3,
          text: 'Wednesday',
        },
        {
          value: 4,
          text: 'Thursday',
        },
        {
          value: 5,
          text: 'Friday',
        },
        {
          value: 6,
          text: 'Saturday',
        },
        {
          value: 7,
          text: 'Sunday',
        },
      ],
      integrations: [],
      fields: ["name", "Row", "EDIT", "DELETE"],
      rowfields: ["name", "Source", "Resource", "Visibility", "status", "sortOrder", "Edit", "Delete"],
      showDayModal: false,
      dayModalMode: '', // 'add' or 'edit'
      showDeleteDayModal: false,
      showDeleteRowModal: false,
      enteredinedit: false,
      showDeleteTimeSlot: false,
      externalIntegrationString: "EXTERNAL_INTEGRATION",
      deleteDayId: '',
      deleteRowId: '',
      newTimeSlotModel: {},
      editDayId: undefined,
      addSlotLength: 0,
      addRepetLength: 0,
      showAddTimeslot: false,
      showEditTimeslot: false,
      languages: [],
      startOfTimeslot: {},
      endOfTimeslot: {},
      expRowOptions: [],
      chosenNewExpType: null,
      selectedIntegration: null,
      day: [],
      itemsVisibility: {},
      oldStartOfTimeslot: {

      },
      oldEndOfTimeslot: {

      },
      experienceDay: {
        name: null,
        order: null,
        description: null,
        id: "00000000-0000-0000-0000-000000000000",
      },
      experienceSchedule: {
        id: "00000000-0000-0000-0000-000000000000",
        timeSelection: 3,
        nameTranslations: [],
        startingPriceTranslations: [],
        duration: {},
        startTime: null,
        endTime: null,
        active: true,
        perDepartureCapacity: {
          minTotal: null,
          maxTotal: null,
          bookings: null,
          guestTypeSpecificCapac: [],
          disabled: true
        },
        perBookingCapacity: {
          minTotal: null,
          maxTotal: null,
          disabled: true,
          label: null,
          guestTypeSpecificCapac: []
        },
        availability: [],
        limit: {},
        bookingCutoff: {},
        concurrentCapacity: {}
      },

      //Alert
      alertType: "danger",
      message: null,
    };
  },
  computed: {
    filteredAvailability() {
      const distinctWeekDays = new Set();
      return this.experienceSchedule.availability.filter((time) => {
        if (!distinctWeekDays.has(time.weekDayName)) {
          distinctWeekDays.add(time.weekDayName);
          return true;
        }
        return false;
      });
    },
    formattedStartTime() {
      if (this.startOfTimeslot.hours != null && this.startOfTimeslot.minutes != null) {
        return `${this.startOfTimeslot.hours.toString().padStart(2, '0')}:${this.startOfTimeslot.minutes.toString().padStart(2, '0')}`;
      } else {
        return '';
      }
    },
    formattedEndTime() {
      if (this.endOfTimeslot.hours != null && this.endOfTimeslot.minutes != null) {
        return `${this.endOfTimeslot.hours.toString().padStart(2, '0')}:${this.endOfTimeslot.minutes.toString().padStart(2, '0')}`;
      } else {
        return '';
      }
    }
  },
  methods: {
    async closeModalAdd(status, evt, accept) {
      debugger;
      let self = this;
      if (accept) {
        if (self.experienceSchedule.id == "00000000-0000-0000-0000-000000000000") {
          await self.create();
        }
        self.newTimeSlotModel.selectedWeekDaysAdd = self.selectedWeekDaysAdd
        self.newTimeSlotModel.endOfTimeslot = self.endOfTimeslot;
        self.newTimeSlotModel.startOfTimeslot = self.startOfTimeslot;
        debugger;
        if (self.addRepetLength == "") {
          self.addRepetLength = null;
        }
        if (self.addSlotLength == "") {
          self.addSlotLength = null;
        }
        self.newTimeSlotModel.addRepetLength = self.addRepetLength;
        self.newTimeSlotModel.addSlotLength = self.addSlotLength;
        self.addSlotLength = null; // Reset the slot length
        self.addRepetLength = null; // Reset the repeat length
        axios
          .post(
            `${this.$apiAdress}/v1/ExperienceSchedule/${self.experienceId}/${self.experienceSchedule.id}/add-timeslot`,
            self.newTimeSlotModel
          )
          .then((response) => {
                      self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
            self.experienceId = self.$route.params.experienceId;
            self.get(self.experienceSchedule.id);
            self.message = "Successfully updated experience schedule.";
            self.selectedWeekDaysAdd = [];
            self.startOfTimeslot = {
              hours: null,
              minutes: null,
            }; self.endOfTimeslot = {
              hours: null,
              minutes: null,
            };
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error.response.data.errors;
          });
      } else {
        self.selectedWeekDaysAdd = [];
        self.startOfTimeslot = {
          hours: null,
          minutes: null,
        }; self.endOfTimeslot = {
          hours: null,
          minutes: null,
        };
        self.addSlotLength = null; // Reset the slot length
        self.addRepetLength = null; // Reset the repeat length
        self.$refs.startTimeslotInput.value = '';
        self.$refs.endTimeslotInput.value = '';
      }
      this.$refs.multiSelect.clearSelection();
      this.selectedWeekDaysAdd = null;

      this.$refs.startTimeslotInput.value = '';
      this.$refs.endTimeslotInput.value = '';
      this.selectedSlotId2 = new Date().getTime();
      this.selectedWeekDaysAdd = [];
      this.startOfTimeslot = {
        hours: null,
        minutes: null,
      }; this.endOfTimeslot = {
        hours: null,
        minutes: null,
      };
      this.showAddTimeslot = false;
    },
    async closeModalEdit(status, evt, accept) {
      let self = this;
      if (accept) {
        if (self.experienceSchedule.id == "00000000-0000-0000-0000-000000000000") {
          await self.create();
        }
        self.newTimeSlotModel.selectedWeekDaysAdd = self.selectedWeekDaysAdd
        self.newTimeSlotModel.endOfTimeslot = self.endOfTimeslot;
        self.newTimeSlotModel.startOfTimeslot = self.startOfTimeslot;
        debugger;
        if (self.addRepetLength == "") {
          self.addRepetLength = null;
        }
        if (self.addSlotLength == "") {
          self.addSlotLength = null;
        }
        debugger;
        self.newTimeSlotModel.addRepetLength = self.addRepetLength;
        self.newTimeSlotModel.addSlotLength = self.addSlotLength;
        if (self.oldStartOfTimeslot.hours == null) {
          self.oldStartOfTimeslot.hours = 0;
        }
        if (self.oldStartOfTimeslot.minutes == null) {
          self.oldStartOfTimeslot.minutes = 0;
        }
        if (self.oldEndOfTimeslot.minutes == null) {
          self.oldEndOfTimeslot.minutes = 0;
        }
        if (self.oldEndOfTimeslot.hours == null) {
          self.oldEndOfTimeslot.hours = 0;
        }
        self.newTimeSlotModel.oldStartOfTimeslot = self.oldStartOfTimeslot;
        self.newTimeSlotModel.oldEndOfTimeslot = self.oldEndOfTimeslot;
        self.newTimeSlotModel.id = self.selectedSlotId;
        axios
          .post(
            `${this.$apiAdress}/v1/ExperienceSchedule/${self.experienceId}/${self.experienceSchedule.id}/update-timeslot`,
            self.newTimeSlotModel
          )
          .then((response) => {
                      self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
            self.experienceId = self.$route.params.experienceId;
            self.get(self.experienceSchedule.id);
            self.selectedWeekDaysAdd = [];

            self.message = "Successfully updated experience schedule.";
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error.response.data.errors;
          });
      } else {
        self.selectedWeekDaysAdd = [];

      }
      this.showEditTimeslot = false;
    },
    closeModalDelete(status, evt, accept) {
      debugger;
      let self = this;
      if (accept) {
        self.newTimeSlotModel.selectedWeekDaysAdd = self.selectedWeekDaysAdd
        self.newTimeSlotModel.endOfTimeslot = self.endOfTimeslot;
        self.newTimeSlotModel.startOfTimeslot = self.startOfTimeslot;
        debugger;
        if (self.addRepetLength == "") {
          self.addRepetLength = null;
        }
        if (self.addSlotLength == "") {
          self.addSlotLength = null;
        }
        self.newTimeSlotModel.addRepetLength = self.addRepetLength;
        self.newTimeSlotModel.addSlotLength = self.addSlotLength;
        self.newTimeSlotModel.id = self.selectedSlotId;
        axios
          .post(
            `${this.$apiAdress}/v1/ExperienceSchedule/${self.experienceId}/${self.experienceSchedule.id}/delete-timeslot`,
            self.newTimeSlotModel
          )
          .then((response) => {
                      self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
            self.experienceId = self.$route.params.experienceId;
            self.get(this.$route.params.id);
            self.selectedWeekDaysAdd = [];

            self.message = "Successfully updated experience schedule.";
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error.response.data.errors;
          });
        this.showEditTimeslot = false;

      } else {
        self.selectedWeekDaysAdd = [];

      }
      this.showDeleteTimeSlot = false;
    },
    appendValueAdd(event) {
      debugger;
      this.selectedWeekDaysAdd = event;
    },
    openDeleteTimeSlotConfirm() {
      this.showDeleteTimeSlot = true;

    },
    initperDepartureCapacity(guestType) {
      debugger;
      this.experienceSchedule.perDepartureCapacity.guestTypeSpecificCapac.push({
        disabled: false,
        min: null,
        max: null,
        bookings: null,
        guestTypeId: guestType.value,
        label: guestType.label
      });
    },
    initperBookingCapacity(guestType) {
      debugger;
      this.experienceSchedule.perBookingCapacity.guestTypeSpecificCapac.push({
        disabled: false,
        min: null,
        max: null,
        guestTypeId: guestType.value,
        label: guestType.label

      });
    },
    openAddTimeslot() {
      this.showAddTimeslot = true;
      this.selectedSlotId2 = new Date().getTime();
    },
    mapDayToOption(day) {
      return this.options.find(option => option.value === day);
    },
    addGuestTypeLabel(guestTypeData, expRowData) {
      const expRowGuestTypeIdx = expRowData?.findIndex(g => g.guestTypeId === guestTypeData.value);
      debugger;
      if (expRowGuestTypeIdx > -1) {
        let modifiedData = { ...expRowData[expRowGuestTypeIdx], label: guestTypeData.label };
        expRowData.splice(expRowGuestTypeIdx, 1, modifiedData); // https://michaelnthiessen.com/debugging-guide-why-your-component-isnt-updating/
      }
      return expRowGuestTypeIdx;
    },
    async getGuestTypes() {
      let self = this;
      await axios
        .get(this.$apiAdress + '/v1/ListItem/GuestTypeList?partnerId=' + this.partnerId + '&experienceId=' + this.experienceId)
        .then((response) => {
          if (response && response.data) {
            const guestTypes = response.data;
            debugger;
            if (self.$route.params.id != self.emptyGuid) {
              if (!self.experienceSchedule.perDepartureCapacity.guestTypeSpecificCapac) self.experienceSchedule.perDepartureCapacity.guestTypeSpecificCapac = [];
              if (!self.experienceSchedule.perBookingCapacity.guestTypeSpecificCapac) self.experienceSchedule.perBookingCapacity.guestTypeSpecificCapac = [];

              // if guestType data already exists on the experience row, add guestType labels
              debugger;
              for (let guestType of guestTypes) {
                const idxC = self.addGuestTypeLabel(guestType, this.experienceSchedule.perDepartureCapacity.guestTypeSpecificCapac);
                if (idxC === -1) {
                  self.initperDepartureCapacity(guestType);
                }

                const idxCC = self.addGuestTypeLabel(guestType, this.experienceSchedule.perBookingCapacity.guestTypeSpecificCapac);
                if (idxCC === -1) {
                  self.initperBookingCapacity(guestType);
                }

              }
            } else {
              // otherwise init guestType data
              self.experienceSchedule.perDepartureCapacity.guestTypeSpecificCapac = [];
              self.experienceSchedule.perBookingCapacity.guestTypeSpecificCapac = [];

              for (let guestType of guestTypes) {
                self.initperDepartureCapacity(guestType);
                self.initperBookingCapacity(guestType);
              }
            }
          }
        })
        .catch((error) => {
                    this.alertType = "danger";
          setTimeout(function () {
              this.message = null;
          }, 10000);
          this.message = error;
        });
    },
    // Function to add a day to selectedWeekDaysAdd
    openEditTimeslot(group) {
      this.addRepetLength = group.repetLength;
      this.addSlotLength = group.slotLength;
      this.selectedWeekDaysAdd = [];
      debugger;
      this.oldEndOfTimeslot.hours = group.end.hours;
      this.oldEndOfTimeslot.minutes = group.end.minutes;
      this.oldStartOfTimeslot.hours = group.start.hours;
      this.oldStartOfTimeslot.minutes = group.start.minutes;

      for (const key in this.grouped) {
        debugger;
        if (this.grouped.hasOwnProperty(key)) {
          const groups = this.grouped[key];
          for (const item of groups) {
            if (
              item.start.hours === group.start.hours &&
              item.start.minutes === group.start.minutes &&
              item.end.hours === group.end.hours &&
              item.end.minutes === group.end.minutes
            ) {
              this.selectedWeekDaysAdd.push(item.day);
            }
          }
        }
      }

      debugger;
      this.startOfTimeslot = {
        hours: group.start.hours,
        minutes: group.start.minutes,
      };
      this.endOfTimeslot = {
        hours: group.end.hours,
        minutes: group.end.minutes,
      };
      this.selectedSlotId = group.id;
      this.showEditTimeslot = true;
      this.enteredinedit = true;
    },
    appendValueEdit(event) {
      this.selectedWeekDaysEdit = event;
    },
    groupedTimes() {
      const grouped = {};
      debugger;
      for (const time of this.experienceSchedule.availability) {
        if (time.id != "00000000-0000-0000-0000-000000000000") {
          const weekDayName = time.weekDayName;

          if (!grouped[weekDayName]) {
            grouped[weekDayName] = [];
          }
          debugger;
          if (time.end.hours != null && time.end.minutes != null && time.start.hours != null && time.start.minutes != null) {
            grouped[weekDayName].push(time);

          }
        }

      }
      debugger;
      return grouped;
    },
    groupByDay() {
      const grouped = {};
      for (const day of Object.keys(this.grouped)) {
        const items = this.grouped[day];
        if (!grouped[day]) {
          grouped[day] = [];
        }
        for (const item of items) {
          grouped[day].push(item);
        }
      }
      return grouped;
    },
    getVisibilityBadge(visibility) {
      switch (visibility) {
        case 0:
          return "success";
        case 1:
          return "secondary";
        default:
          return "warning";
      }
    },
    getStatusBadge(status) {
      switch (status) {
        case true:
          return "success";
        case false:
          return "secondary";
        default:
          return "primary";
      }
    },
    toggle(ei) {
      this.itemsVisibility[ei] = !this.itemsVisibility[ei];
    },
    closeDayModal(status, evt, accept) {
      if (accept) {
        this.addNewDay();
      }
    },
    closeDeleteModal(status, evt, accept) {
      if (accept) {
        this.deleteExperienceDay(this.experienceDay.id);
      }
    },
    deleteExperienceDay(id) {
      let self = this;
      axios
        .delete(
          this.$apiAdress +
          "/v1/ExperienceDay/" +
          self.experienceSchedule.id +
          "/" +
          id
        )

        .then(function () {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully deleted experience day.";
          self.getExperienceDays();
        })
        .catch(function (error) {
          self.message = error;
        });
    },
    createExpRowOptions() {
      let arr = [];
      arr.push({ value: null, label: "Choose" });
      for (let key of Object.keys(ExperienceTypesNames)) {
        arr.push({ value: key, label: ExperienceTypesNames[key] });
      }
      return arr;
    },
    openExpRowAddModal(id) {
      this.editDayId = id;
    },
    chooseExpType(type) {
      this.chosenNewExpType = type;
    },
    chooseIntegration(integrationId) {
      this.selectedIntegration = integrationId;
    },
    modalAction(_, evt, accept) {
      if (accept) {
        if (this.chosenNewExpType == null) return;
        this.addExperienceRow()
      } else {
        this.editDayId = undefined;
      }
    },
    addExperienceRow(id) {
      let rowId = id ? id : this.emptyGuid;
      this.$router.push({ path: `/experiences/schedule/day/${this.editDayId}/row/${rowId}`, query: { op: 'create', expType: this.chosenNewExpType, scheduleId: this.experienceSchedule.id, experienceId: this.experienceId, integrationType: this.selectedIntegration } });
      this.editDayId = undefined;
    },
    editExperienceRow(dayId, id) {
      this.editRow(dayId, id);
    },
    closeDeleteRowModal(status, evt, accept) {
      if (accept) {
        this.deleteExperienceRow(this.deleteDayId, this.deleteRowId);
      }
    },
    deleteExperienceRow(experienceDayId, experienceRowId) {
      let self = this;
      axios
        .delete(`${this.$apiAdress}/v1/ExperienceRow/${experienceDayId}/${experienceRowId}`)
        .then(function () {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully deleted experience row.";
          if (self.$route.params.id != self.emptyGuid) {
            self.get(self.$route.params.id);
          }
        })
        .catch(function (error) {
          self.message = error;
        });
    },
    addNewDay() {
      let self = this;
      axios
        .post(
          `${this.$apiAdress}/v1/ExperienceDay/${self.experienceSchedule.id}`,
          self.experienceDay
        )

        .then(function () {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully added experience day.";
          self.experienceDay.name = null;
          self.experienceDay.order = null;
          self.experienceDay.description = null;
          self.experienceDay.id = "00000000-0000-0000-0000-000000000000";
          self.getExperienceDays();
        })
        .catch(function (error) {
          self.message = error;
        });
    },
    dayLink(id) {
      return `/experiences/schedule/day/${id.toString()}`;
    },
    rowLink(dayId, id) {
      return `/experiences/schedule/day/${dayId.toString()}/row/${id.toString()}`;
    },
    editDay(id) {
      const dayLink = this.dayLink(id);
      this.$router.push({ path: dayLink });
    },
    editRow(dayId, id) {
      const rowLink = this.rowLink(dayId, id);
      const day = this.items.find(item => item.id === dayId);
      const expRow = day.experienceRows.find(row => row.id === id);
      this.$router.push({ path: rowLink, query: { op: 'edit', scheduleId: this.experienceSchedule.id, sourceType: expRow.sourceType, experienceId: this.experienceId } });
    },
    goBack() {
      this.$router.go(-1);
    },
    getLanguages() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/PartnerLanguage`)
        .then(function (response) {
          self.languages = response.data;
          for (let i = 0; i < self.languages.length; i++) {
            if (!self.experienceSchedule.nameTranslations.find(t => t.languageCode === self.languages[i].languageCode)) {
              self.experienceSchedule.nameTranslations.push({
                languageCode: self.languages[i].languageCode,
                name: null,
                languageId: self.languages[i].languageId,
              });
            }

            if (!self.experienceSchedule.startingPriceTranslations.find(t => t.languageCode === self.languages[i].languageCode)) {
              self.experienceSchedule.startingPriceTranslations.push({
                languageCode: self.languages[i].languageCode,
                name: null,
                languageId: self.languages[i].languageId,
              });
            }
          }
          let test = self.experienceSchedule.nameTranslations;
          self.experienceSchedule.nameTranslations = [];
          test.map(function (value, key) {
            var exists = self.languages.find(x => x.languageCode == value.languageCode);
            if (exists != undefined) {
              self.experienceSchedule.nameTranslations.push(value);
            }
          });


          let testStartingPriceTranslation = self.experienceSchedule.startingPriceTranslations;
          self.experienceSchedule.startingPriceTranslations = [];
          // const distinct = Object.values(
          //   testStartingPriceTranslation.reduce((o, a) => { o[a.languageCode] = a; return o}, {}));
          testStartingPriceTranslation.map(function (value, key) {
            var exists = self.languages.find(x => x.languageCode == value.languageCode);
            if (exists != undefined) {
              self.experienceSchedule.startingPriceTranslations.push(value);
            }
          });
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getExperienceDays() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/ExperienceDay/${self.experienceSchedule.id}`)
        .then(function (response) {
          self.items = response.data;
          for (let item of self.items) {
            self.itemsVisibility = { // https://michaelnthiessen.com/debugging-guide-why-your-component-isnt-updating/
              ...self.itemsVisibility,
              [item.id]: true
            };
            for (let expRow of item.experienceRows) {
              if (expRow.descriptionTranslations) {
                let english = expRow.descriptionTranslations.find(e => e.languageCode === 'en-US');
                if (english && english.name) expRow.name = english.name;
              }
              expRow.source = SourceTypes[expRow.sourceType];
              if (expRow.inventory && expRow.inventory.name) expRow.resource = expRow.inventory.name;
            }
          }
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },

    saveStartTime: function (value, event) {
      let self = this;
      self.experienceSchedule.availability[value].start = {}
      self.experienceSchedule.availability[value].start.hours = event.target.value.split(':')[0];
      self.experienceSchedule.availability[value].start.minutes = event.target.value.split(':')[1];
    },
    saveEndTime: function (value, event) {
      let self = this;
      self.experienceSchedule.availability[value].end = {}
      self.experienceSchedule.availability[value].end.hours = event.target.value.split(':')[0];
      self.experienceSchedule.availability[value].end.minutes = event.target.value.split(':')[1];
    },


    saveEndTimeslotTime: function (value, event) {
      let self = this;
      self.endOfTimeslot = {}
      self.endOfTimeslot.hours = event.target.value.split(':')[0];
      self.endOfTimeslot.minutes = event.target.value.split(':')[1];
    },

    saveStartTimeslotTime: function (value, event) {
      let self = this;
      self.startOfTimeslot = {}
      self.startOfTimeslot.hours = event.target.value.split(':')[0];
      self.startOfTimeslot.minutes = event.target.value.split(':')[1];
    },
    // The create method is now an async function
    async create() {
      let self = this;

      // Set empty strings to null
      self.experienceSchedule.startTime = self.experienceSchedule.startTime || null;
      self.experienceSchedule.endTime = self.experienceSchedule.endTime || null;
      self.experienceSchedule.perDepartureCapacity.bookings = self.experienceSchedule.perDepartureCapacity.bookings || null;
      self.experienceSchedule.perDepartureCapacity.minTotal = self.experienceSchedule.perDepartureCapacity.minTotal || null;
      self.experienceSchedule.perDepartureCapacity.maxTotal = self.experienceSchedule.perDepartureCapacity.maxTotal || null;
      self.experienceSchedule.perBookingCapacity.maxTotal = self.experienceSchedule.perBookingCapacity.maxTotal || null;
      self.experienceSchedule.perBookingCapacity.minTotal = self.experienceSchedule.perBookingCapacity.minTotal || null;

      // Reset guestTypeSpecificCapac arrays and repopulate
      self.experienceSchedule.perBookingCapacity.guestTypeSpecificCapac =
        self.experienceSchedule.perBookingCapacity.guestTypeSpecificCapac.map(value => ({
          ...value,
          min: value.min || null,
          max: value.max || null,
          guestTypeId: value.guestTypeId
        }));

      self.experienceSchedule.perDepartureCapacity.guestTypeSpecificCapac =
        self.experienceSchedule.perDepartureCapacity.guestTypeSpecificCapac.map(value => ({
          ...value,
          min: value.min || null,
          max: value.max || null,
          guestTypeId: value.guestTypeId
        }));

      try {
        // Make the POST request using Axios and await its result
        const response = await axios.post(
          `${this.$apiAdress}/v1/ExperienceSchedule/${self.experienceId}`,
          self.experienceSchedule
        );

        // Update UI on success
                  self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
        self.message = "Successfully updated experience schedule.";
        self.experienceSchedule.id = response.data;

        // Return the response if you need to use it after calling create
        return response;

      } catch (error) {
        // Handle errors from the POST request
                  self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
        self.message = error.response ? error.response.data.errors : error.message;
        throw error; // Re-throw the error if you want to handle it later as well
      }
    },

    getAllIntegrations() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/PartnerIntegration/list")
        .then(function (response) {
          self.integrations = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message;
        })
    },
    async get(id) {
      let self = this;
      await axios
        .get(`${this.$apiAdress}/v1/experienceSchedule/${self.experienceId}/${id}`)
        .then(function (response) {
          self.experienceSchedule = response.data;

          debugger;
          if (!self.experienceSchedule.bookingCutoff) {
            self.experienceSchedule.bookingCutoff = { days: 0, hours: 0, minutes: 0 };
          }

          if (!self.experienceSchedule.nameTranslations) {
            self.experienceSchedule.nameTranslations = [];
          }

          if (!self.experienceSchedule.startingPriceTranslations) {
            self.experienceSchedule.startingPriceTranslations = [];
          }

          if (!self.experienceSchedule.duration) {
            self.experienceSchedule.duration = { days: 0, hours: 0, minutes: 0 };
          }

          if (!self.experienceSchedule.limit) {
            self.experienceSchedule.limit = { minDays: 0, maxDays: 0 };
          }
          if (!self.experienceSchedule.concurrentCapacity) {
            self.experienceSchedule.concurrentCapacity = { minGuests: 0, maxGuests: 0, bookings: 0 };
          }

          if (self.experienceSchedule.startTime) {
            self.experienceSchedule.startTime = new Date(
              self.experienceSchedule.startTime
            );
          }

          if (self.experienceSchedule.perBookingCapacity == null) {
            self.experienceSchedule.perBookingCapacity = {};
          }

          if (self.experienceSchedule.perDepartureCapacity == null) {
            self.experienceSchedule.perDepartureCapacity = {};
          }

          if (self.experienceSchedule.endTime) {
            self.experienceSchedule.endTime = new Date(
              self.experienceSchedule.endTime
            );
          }
          debugger;
          self.grouped = self.groupedTimes();
          self.groupByDay = self.groupByDay();
          self.getExperienceDays();
          self.getLanguages();

        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error.data;
        });
    },
  },
  mounted: async function () {
    this.expRowOptions = this.createExpRowOptions();
    let self = this;
    self.getAllIntegrations();
    self.experienceId = self.$route.params.experienceId;
    await self.get(this.$route.params.id);
    await self.getGuestTypes();
  },


};
</script>
