/* Commonly used enums etc */

// Experience types enum and names, which are used in front-end
export const ExperienceTypesEnum = {
  INVENTORY: 'INVENTORY',
  PARTNER_EXPERIENCE: 'PARTNER_EXPERIENCE',
  EXTERNAL_INTEGRATION: 'EXTERNAL_INTEGRATION'
};

export const ExperienceTypesNames = {
  INVENTORY: 'Inventory',
  PARTNER_EXPERIENCE: 'Partner experience',
  EXTERNAL_INTEGRATION: 'External integration'
}

export const WeekDayNames = { 1: 'Monday', 2: 'Tuesday', 3: 'Wednesday', 4: 'Thursday', 5: 'Friday', 6: 'Saturday', 7: 'Sunday' };

export const SourceTypes = { 0: 'Inventory', 1: 'Partner Experience', 2: 'External' };

export default {
  buildFormData: function(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
  
      formData.append(parentKey, value);
    }
  },
}

